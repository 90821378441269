<template>
  <div class="invoice-body" id="invoice-body">
    <div v-show="show" id="invoice"  style="height: 100%;">
      <div :style="{'padding': '10px','background-color': '#ffffff',
         'direction': 'rtl', 'text-align': 'right', 'height': '100%' }">
        <div v-if="rel_logo" style="width: 100%; text-align: center;padding-bottom: 10px;padding-top: 15px;"><img :src="rel_logo" alt="" style="width: 80px;"></div>
<!--        <h4 v-if="(data.customer && data.customer.tax_register) && data.is_company_have_tax" class="text-center font-weight-800">فاتورة ضريبية TAX INVOICE</h4>-->
<!--        <h4 v-else-if="data.is_company_have_tax" class="text-center font-weight-800">فاتورة ضريبية مبسطة SIMPLE TAX INVOICE</h4>-->
<!--        <h4 v-else class="text-center font-weight-800">فاتورة INVOICE</h4>-->
        <h4  class="text-center font-weight-800">{{data ? data.invoice_title_ar: ''}} {{data ? data.invoice_title_en : '' }}</h4>

        <h4 v-if="inContentTemp('invoice_code')" class="text-center font-weight-800" style="margin-top: 15px">
          رقم الفاتورة: {{ data.invoice_code }}
        </h4>

        <h3 class="text-center" style="margin-bottom: 5px;margin-top: 5px;" v-if="data.company && data.company.business_name && inContentTemp('business_name_ar')">{{ data.company.business_name }}</h3>
        <template v-if="data.branch">
          <h5 class="text-center" style="margin-bottom: 5px;margin-top: 5px;" v-if="data.branch.name">{{ data.branch.name }}</h5>
          <p class="text-center" style="margin-bottom: 5px;margin-top: 5px;">
            <span v-if="data.branch.country">{{ data.branch.country ? data.branch.country : ''}}</span>
            <span v-if="data.branch.city">{{ data.branch.city ? ' - ' + data.branch.city : '' }}</span>
            <span v-if="data.branch.address_1">{{data.branch.address_1 ? ' - ' + data.branch.address_1 : ''}}</span>
          </p>
        </template>
        <!--        <p class="text-center" style="margin-bottom: 5px;margin-top: 5px;">-->
<!--          <span v-if="inContentTemp('company_city_name')">{{ data.company ? (data.company.city_name ? ' - ' + data.company.city_name : '') : '' }}</span>-->
<!--          <span v-if="inContentTemp('company_state')">{{ data.company ? (data.company.state ? ' - ' + data.company.state : '') : '' }}</span>-->
<!--          <span v-if="inContentTemp('company_address_1')">{{ data.company ? (data.company.address_1 ? ' - ' + data.company.address_1 : '') : '' }}</span>-->
<!--          <span v-if="inContentTemp('company_postal_code')">{{ data.company ? (data.company.postal_code ? ' - ' + data.company.postal_code : '') : '' }}</span>-->
<!--        </p>-->

        <div v-if="data.pos_store && is_show_contact && (mobileFullData(data.pos_store.mobile) || mobileFullData(data.pos_store.mobile2))" style="width: 100%;display: block;background-color: #cccccc;">
          <h5 style="margin-bottom: 0;text-align: center;padding-top: 5px;padding-bottom: 5px;">
            <span>التواصل: </span>
            <span v-if="mobileFullData(data.pos_store.mobile)" style="direction: ltr;display: inline-block;"> {{ mobileFullData(data.pos_store.mobile) }}</span>
            <template v-if="mobileFullData(data.pos_store.mobile2)">
              <span style="direction: ltr;display: inline-block;margin-right: 5px;margin-left: 5px;" v-if="mobileFullData(data.pos_store.mobile2) "> - </span>
              <span style="direction: ltr;display: inline-block;"> {{ mobileFullData(data.pos_store.mobile2) }}</span>
            </template>
          </h5>
        </div>
        <p  class="text-center" v-if="data.company && data.company.business_record && inContentTemp('business_record')" style="margin-bottom: 5px;margin-top: 5px;">
          <span><b> السجل التجاري: </b></span>
          <span>{{ data.company.business_record }}</span>
        </p>
        <p class="text-center" v-if="data.company && data.company.tax_no && inContentTemp('tax_no')" style="margin-bottom: 5px;margin-top: 5px;">
          <span><b> الرقم الضريبي: </b></span>
          <span>{{ data.company.tax_no }}</span>
        </p>
        <p><span style="border: 1px solid #000;width: 70%;height: 0px;display: block;margin: 0 auto;"></span></p>

        <div style="border-right: 1px solid #000000;margin-top: 15px;padding: 5px 10px;">
          <p style="margin-bottom: 0;margin-top: 0;" v-if="inContentTemp('invoice_date')"><span><b> تاريخ الفاتورة: </b> <span> {{ data.create_date_time }} </span></span></p>
          <p style="margin-bottom: 0;margin-top: 10px;">
            <span v-if="inContentTemp('employee_user_name') && data.employee_user_name">
              <b> أمين الصندوق: </b>
              <span > {{ data.employee_user_name }} </span>
            </span>
          </p>
        </div>

        <div v-if="is_show_customer_details" style="border-right: 1px solid #000000;margin-top: 15px;padding: 0px 10px;">
          <p style="margin-bottom: 0;display: flex;justify-content: space-between;width: 100%;" v-if="data.customer">
            <span v-if="inContentTemp('fullname') && data.customer.fullname" style="width: 100%;"><span><b> العميل: </b> </span><span> {{ data.customer.fullname }} </span></span>
            <span v-if="customer_branch && data.customer.customer_branch_name" style="width: 100%;"><span> </span><span> {{ data.customer.customer_branch_name }} </span></span>
            <span v-if="inContentTemp('customer_mobile') && mobileFullData(data.customer.mobile) && data.customer.mobile" style="margin-right: 10px;margin-left: 10px;"> | </span>
            <span v-if="inContentTemp('customer_mobile') && mobileFullData(data.customer.mobile)" style="direction: ltr;display: inline-block;width: 100%;"> {{ mobileFullData(data.customer.mobile) }} </span>
          </p>
          <p style="margin-bottom: 0;width: 100%;" v-if="data.customer && data.customer.tax_register && inContentTemp('tax_register')">
            <span><b> الرقم الضريبي: </b></span>
            <span>{{ data.customer.tax_register }}</span>
          </p>
          <p style="margin-bottom: 0;width: 100%;" v-if="data.customer && data.customer.commercial_register && inContentTemp('commercial_register')">
            <span><b> السجل التجاري: </b></span>
            <span>{{ data.customer.commercial_register }}</span>
          </p>

          <p style="margin-bottom: 0;display: flex;justify-content: space-between;width: 100%;" v-if="data.customer">
            <span v-if="inContentTemp('customer_code')" style="width: 100%;"><span><b> رقم العميل: </b> </span><span v-if="data.customer.customer_code">{{ data.customer.customer_code }}#</span></span>
            <span v-if="(inContentTemp('customer_debit') && data.customer_balance && data.customer_balance.debit) || (inContentTemp('customer_credit') && data.customer_balance && data.customer_balance.credit )" style="margin-right: 10px;margin-left: 10px;">|</span>
            <span style="width: 100%;" v-if="data.customer_balance && data.customer_balance.credit && inContentTemp('customer_credit')">
              <span><b> الرصيد: </b> </span><span>{{ data.customer_balance.credit }}</span>
            </span>
            <span v-else-if="data.customer_balance && data.customer_balance.debit && inContentTemp('customer_debit')">
              <span style="width: 100%;"><b> الرصيد المستحق: </b> </span><span>{{ data.customer_balance.debit }}</span>
            </span>
          </p>
<!--          <p  style="margin-bottom: 0;" v-if="data.customer && data.customer.tax_register && inContentTemp('tax_register')"><span><b> الرقم الضريبي: </b> </span><span> {{ data.customer.tax_register }} </span></p>-->
        </div>

        <p><span style="border: 1px solid #000;width: 70%;height: 0px;display: block;margin: 0 auto;"></span></p>


        <div v-if="products_template_design == 1" style="margin-top: 15px;">
          <div style="border-right: 1px solid #000000;margin-top: 15px;padding: 0px 10px;" v-for="(row, index) in items_list" :key="index">
            <div style="margin-bottom: 0;display: flex;justify-content: space-between;width: 100%;">
              <p style="margin: 0;" v-if="inProductTableSetting('item')">
                <span v-if="index == 0" style="display: block;"><b>{{getLabelShortcut('product_name', 'ar')}}</b></span>
                <span style="display: block;">{{ row.item ? row.item.name : '' }}</span>
              </p>
              <p style="margin: 0;" v-if="inProductTableSetting('unit')">
                <span v-if="index == 0" style="display: block;"><b>{{getLabelShortcut('unit', 'ar')}}</b></span>
                <span style="display: block;">{{ row.unit_name ? row.unit_name : '' }}</span>
              </p>
              <p style="margin: 0;" v-if="inProductTableSetting('qty')">
                <span v-if="index == 0" style="display: block;"><b>{{getLabelShortcut('quantity', 'en')}}</b></span>
                <span style="display: block;">{{ row.qty }}</span>
              </p>
              <p style="margin: 0;" v-if="inProductTableSetting('unit_price')">
                <span v-if="index == 0" style="display: block;"><b>{{getLabelShortcut('unit_price', 'en')}} </b></span>
                <span style="display: block;">{{ row.unit_price }}</span>
              </p>

            </div>
            <div style="margin-bottom: 0;margin-top:10px;display: flex;justify-content: space-between;width: 100%;">
              <p style="margin: 0;display: contents;" v-if="inProductTableSetting('subtotal_before_tax')">
                <span><b>{{getLabelShortcut('total_before_tax', 'ar')}}</b></span>
                <span> {{  row.subtotal_before_tax  }}</span>
              </p>
              <p style="margin: 0;display: contents;" v-if="inProductTableSetting('amount_tax')">
                <span><b>{{getLabelShortcut('tax_val', 'ar')}}</b></span>
                <span> {{ row.amount_tax ? row.amount_tax : '0' }}</span>
              </p>
              <p style="margin: 0;display: contents;" v-if="inProductTableSetting('discount_calc')">
                <span><b>{{getLabelShortcut('discount_calc', 'ar')}}</b></span>
                <span> {{ row.discount_calc ? row.discount_calc : '0' }}</span>
              </p>
              <p style="margin: 0;display: contents;" v-if="inProductTableSetting('subtotal_after_tax')">
                <span><b>{{getLabelShortcut('total_after_tax', 'ar')}}</b></span>
                <span> {{ row.subtotal_after_tax ? row.subtotal_after_tax : '0' }}</span>
              </p>
            </div>

            <p><span style="border-bottom: 2px dotted #000;width: 70%;height: 0;display: block;margin: 0 auto;"></span></p>
          </div>
        </div>
        <div v-else-if="products_template_design == 2" style="margin-top: 15px;">
          <table class="table-p" style="margin-top: 15px">
            <thead>
            <tr>
              <th v-if="inProductTableSetting('item')">{{getLabelShortcut('product_name', 'ar')}}</th>
              <th v-if="inProductTableSetting('unit')">{{getLabelShortcut('unit', 'ar')}}</th>
              <th class="text-center" v-if="inProductTableSetting('qty')">{{getLabelShortcut('quantity', 'ar')}}</th>
              <th class="text-center" v-if="inProductTableSetting('unit_price')">{{getLabelShortcut('unit_price', 'ar')}}</th>
              <!--            <th class="text-center">الخصم</th>-->
              <template v-if="data.is_apply_taxes_invoice">
                <th class="text-center" v-if="inProductTableSetting('amount_tax')">{{getLabelShortcut('tax_val', 'ar')}}</th>
                <th class="text-center" v-if="inProductTableSetting('subtotal_before_tax')">{{getLabelShortcut('total_before_tax', 'ar')}}</th>
                <th class="text-center" v-if="inProductTableSetting('subtotal_after_tax')">{{getLabelShortcut('total_after_tax', 'ar')}}</th>
              </template>
              <template  v-else-if="inProductTableSetting('subtotal_after_tax')">
                <th class="text-center">{{getLabelShortcut('total_after_tax', 'ar')}}</th>
              </template>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(row, index) in items_list" :key="index">
              <td style="overflow: hidden;" v-if="inProductTableSetting('item')">
                <!-- <span style="width:50px;display:block;word-wrap: unset;white-space: nowrap;text-overflow: ellipsis;"> -->
                <span>
                {{ row.item ? row.item.name : '' }}
              </span>
              </td>
              <td class="text-center" v-if="inProductTableSetting('unit')">{{ row.unit_name }}</td>
              <td class="text-center" v-if="inProductTableSetting('qty')">{{ row.qty }}</td>
              <td class="text-center" v-if="inProductTableSetting('unit_price')">{{ row.unit_price }}</td>
              <!--            <td class="text-center">{{ row.discount_calc ? row.discount_calc : '0' }}</td>-->
              <template v-if="data.is_apply_taxes_invoice">
                <td class="text-center" v-if="inProductTableSetting('amount_tax')">{{ row.amount_tax ? row.amount_tax : '0' }}</td>
                <td class="text-center" v-if="inProductTableSetting('subtotal_before_tax')">{{ row.subtotal_before_tax  ? row.subtotal_before_tax  : '0' }}</td>
                <td class="text-center" v-if="inProductTableSetting('subtotal_after_tax')">{{ row.subtotal_after_tax ? row.subtotal_after_tax : '0' }}</td>
              </template>
              <template v-else-if="inProductTableSetting('subtotal_after_tax')">
                <td class="text-center">{{ row.subtotal_after_tax ? row.subtotal_after_tax : '0' }}</td>
              </template>
            </tr>
            </tbody>
          </table>
        </div>

        <div style="margin-top: 15px;">
          <div style="min-width: 264px;padding-left: 5px;">
            <div class="d-flex justify-content-between special-border mt-1" v-if="inContentTemp('invoice_subtotal')">
              <div><h5 class="mb-0">الاجمالي قبل الضريبة \ Sub Total</h5></div>
              <div><span><b>{{ data.invoice_total_before_taxes ? data.invoice_total_before_taxes : '0' }}</b></span> <span> {{ data.currency_code }} </span></div>
            </div>
            <div class="d-flex justify-content-between special-border mt-1" v-if="inContentTemp('invoice_total_discount')">
              <div><h5 class="mb-0">الخصم \ Discount</h5></div>
              <div><span class="sum-sum"><b> {{ data.invoice_total_discount ? data.invoice_total_discount : '0' }} </b></span> <span>{{ data.currency_code }}</span></div>
            </div>

<!--            <div class="d-flex justify-content-between special-border mt-1" v-if="inContentTemp('invoice_total_taxes')">-->
<!--              <h5 class="mb-0">-->
<!--              <span>-->
<!--                ضريبة القيمة المضافة \ VAT 15%-->
<!--              </span>-->
<!--              </h5>-->
<!--              <div><span class="sum-sum"><b> {{ data.invoice_total_taxes ? data.invoice_total_taxes : '0' }} </b></span> <span> {{ data.currency_code }} </span></div>-->
<!--            </div>-->

            <div class="d-block" style="padding: 0 15px;" v-if="inContentTemp('invoice_total_taxes') && data.is_apply_taxes_invoice && data.grouped_taxes">
              <div class="d-flex justify-content-between special-border mt-1" v-for="(row, index) in data.grouped_taxes" :key="'tax'+index">
                <h5 class="mb-0"><span>{{row.name}}</span></h5>
                <div><span class="sum-sum"><b> {{ row.tax_value ? row.tax_value : '0' }} </b></span> <span> {{ data.currency_code }} </span></div>
              </div>
            </div>

            <div class="d-flex justify-content-between special-border mt-1" v-if="inContentTemp('invoice_total_after_taxes')">
              <h5 class="mb-0">
              <span>
                الاجمالي شامل الضريبة \ Total
              </span>
              </h5>
              <div><span><b>{{ data.invoice_total ? data.invoice_total : '0' }}</b></span> <span> {{ data.currency_code }} </span></div>
            </div>
            <div v-if="is_payment_details  && showPaymentDetails(data.payment_amount_details)" class="d-flex justify-content-between special-border mt-1" style="align-items: start;">
              <div><h5 class="mb-0">طرق الدفع \ Payment methods</h5></div>
              <div>
                <span style="margin-bottom: 10px;display: block;" v-for="(_payment , _index) in data.payment_amount_details" :key="'_payment'+_index">
                  <template v-if="_payment.payment_method_name && _payment.amount != 0 && _payment.amount != null">
                    <span> {{ _payment.payment_method_name }} </span> <span> {{ _payment.amount }} </span>
                  </template>
                </span>
              </div>
            </div>
          </div>
          <template v-if="data.status != 1">
            <p><span style="border: 1px solid #000;width: 70%;height: 0px;display: block;margin: 0 auto;"></span></p>
            <div class="text-center " style="margin: 5px auto;width: 190px;height: 203px;margin-top: 15px;">
              <div style="padding: 5px;width: 190px;height: 203px;">
                <qr-code :text="data.data_qr_code ? data.data_qr_code : '45455'" :size="size" :error-level="'L'"></qr-code>
              </div>
            </div>

          </template>

          <div v-if="data.pos_policy && is_show_terms_and_conditions">
            <p v-if="is_break_before_footer"><span style="border: 1px solid #000;width: 70%;height: 0px;display: block;margin: 0 auto;"></span></p>
            <h4 class="text-center">الشروط والأحكام</h4>
            <div class="mb-0 ql-editor" style="float: right;text-align: right;width: 100%;height: auto;padding: 0;"  v-html="data.pos_policy"></div>
          </div>
<!--          <div v-if="data.usage_policy && is_show_terms_and_conditions">-->
<!--            <p><span style="border: 1px solid #000;width: 70%;height: 0px;display: block;margin: 0 auto;"></span></p>-->
<!--            <p>{{ data.usage_policy }}</p>-->
<!--          </div>-->


        </div>

      </div>

    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import VueQRCodeComponent from "vue-qrcode-component";
import i18nService from "@/core/services/i18n.service";
import jsPdfExport from "@/core/config/jsPdfExport";
import jsPdfExport80MM from "@/core/config/jsPdfExport80MM";
import invoicesLabelOfItems from "@/core/config/mix/invoicesLabelOfItems";

// import {mapGetters, mapState} from "vuex";
// import Vue from "vue";

export default {
  name: "point-sale-invoice",
  components: {
    "qr-code": VueQRCodeComponent,
  },
  data() {
    return {
      fetched: false,
      lang: this.$i18n.locale,
      mainRoute: "sales/sales_invoices",
      // uploadEndpoint : "sales_invoices",
      uploadEndpoint: "sales/sales_invoices_attachments",
      upload: "sales_invoices",
      startLink: null,
      idEditing: this.$route.params.sales_invoice_id
          ? this.$route.params.sales_invoice_id
          : null,
      type: this.$route.params.type ? this.$route.params.type : null,
      data: {},
      items_list: [],
      size: 155,
      calc_total: 0,
      show: false,

      templateMainRoute: 'settings/print_setting/type',
      template_for: 'PosSales',
      template: null,
      content_temp: [],
      is_break_before_footer: false,
      is_payment_details: false,
      is_empty_row: false,
      is_show_contact: false,
      is_show_customer_details: false,
      is_show_terms_and_conditions: false,
      products_template_design: 1,
      print_size: '80',
      customer_type: null,
      product_table_setting: [],
      customer_branch: false,
      label_shortcut_details: [],
      is_use_label_shortcut: false,
    };
  },
  computed: {
    rel_logo: function () {
      if (this.data && this.data.company && this.data.company.logo_url) {
        return this.data.company.logo_url;
      } else {
        // return window.location.origin + '/media/logos/sajlha_logo_print.png';
        return '';
      }

    },
    fileName: function (){
      let _invoice_label = this.$i18n.locale ==  'ar' ? 'فاتورة مبيعات' : 'Sales invoice';
      let _customer_name = (this.data.customer && this.data.customer.fullname) ? this.data.customer.fullname : '';
      let _invoice_code = (this.data.invoice_code) ? this.data.invoice_code : '';
      return `${_invoice_label} - ${_customer_name} - ${_invoice_code}`;
    }
  },
  methods: {
    ...invoicesLabelOfItems,
    getLabelShortcut(_slug, lang){
      let _is_use_label = this.is_use_label_shortcut;
      // let _is_use_label = this.is_multi_language_label && this.is_custom_product_size_column;
      return this.getLabelShortcutFromSettingsPrint( _slug, lang, this.label_shortcut_details, _is_use_label);
    },
    mobileFullData(mobile){
      if (mobile){
        if (mobile.length > 5){
          return mobile;
        }
      }
      return null;
    },
    formatNumber(number) {
      // Format the number with commas if it's greater than 5 digits
      return number > 9999 ? new Intl.NumberFormat().format(number) : number;
    },
    showPaymentDetails(payment_amount_details){
      if (payment_amount_details && payment_amount_details.length > 0){
        let status = false;
        payment_amount_details.forEach((row)=>{
          if (row.amount != 0 && row.amount != null){
            status = true;
          }
        });
        return status;
      }else {
        return false;
      }
    },
    async getData() {
      let _id = this.idEditing;
      // await ApiService.get(`${this.mainRoute}/print_last_invoice`).then((response) => {
      await ApiService.get(`${this.mainRoute}/${_id}/print`).then((response) => {
        this.data = response.data.data;
        let _inner_data = response.data.data;
        this.items_list = response.data.data.items_list;

        let _taxes = response.data.data.invoice_total_taxes
            ? parseFloat(response.data.data.invoice_total_taxes) / 100
            : 0;
        let _subtotal = response.data.data.invoice_subtotal
            ? parseFloat(response.data.data.invoice_subtotal)
            : 0;

        let _total_taxes = _subtotal * _taxes;
        Object.assign(_inner_data, {invoice_taxes_total: _total_taxes});

        this.calc_total = response.data.data.invoice_total;

        if (response.data.data && response.data.data.customer) {
          this.customer_type = response.data.data.customer.customer_type;
        }

        this.show = true;
      });
    },

    // async getData() {
    //   let _id = this.idEditing;
    //   await ApiService.get(`${this.mainRoute}/${_id}`).then((response) => {

    //     this.data = response.data.data;
    //     this.items_list = response.data.data.items_list;
    //     // this.show = true;
    //   });
    // },
    async printContent() {
      if (document.getElementById('invoice-body')) {
        const content = document.getElementById('invoice-body').innerHTML;

        const printWindow = window.open('', '_self');

        printWindow.document.write('<html><head><title>Print</title>');
        printWindow.document.write('<style>');
        printWindow.document.write(`

              @media print {
                @page {
                  size: auto;
                  margin: 2mm;
                }
              }
              .store-logo{
                position: absolute;
                top: 0;
                right: 0;
                height: 99px;
                margin: 13px;
              }
              #invoice {
                font-family: "NotoSansArabic";
                width: ${this.print_size}mm;
                position: relative;
              }
              h5{
                margin:0;
              }
              html, body {
                    margin: 0.39mm 0.27mm;
                    background: #EEF0F8;
              }

              table, thead, tr, th, tbody, td {

                font-size: 14px;
                background-color: transparent !important;
              }

              table{
                border: 1px solid #000000;
                border-radius: 6px;
                border-color: #000000 !important;
                width: 100%;
              }

              .text-center {
                text-align: center !important;
              }

              th, p, h5 {

                font-size: 14px !important;

              }

              .special-border {
                padding: 5px 0px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 5px;
              }
              .table td {
                border-left: 1px solid #000000 !important;
                border-top: 0;
              }
              .table thead th {
                border-left: 1px solid #000000;
                border-bottom: 1px solid #000000;
              }
              .table thead tr {
                border: 0 !important;
              }
              .table thead th:last-child {
                border-left: 0 !important;
              }
              .table td:last-child {
                border-left: 0 !important;
              }
              .table th, .table td {
                padding: 5px !important;
              }
.table-p td {
  border-left: 1px solid #000000 !important;
  border-top: 0 !important;
}

.table-p thead th {
  border-left: 1px solid #000000 !important;
  border-bottom: 1px solid #000000 !important;
}

.table-p thead tr {
  border: 0 !important;
}

.table-p thead th:last-child {
  border-left: 0 !important;
}

.table-p td:last-child {
  border-left: 0 !important;
}

.table-p th, .table-p td {
  padding: 5px !important;
}
          `);
        printWindow.document.write('</style>');

        printWindow.document.write('</head><body>');
        printWindow.document.write(content);
        printWindow.document.write('</body></html>');


        setTimeout(() => {
          // printWindow.print();
          // printWindow.close();
          this.completedForPrint();
          // this.printEleShow = false;
        }, 500);
      }
      // window.print();
      // window.close();
      // that.printWindow = window.open('', '_blank');
      // that.printWindow.document.write('<html><head><title>Print</title></head><body>');
      // that.printWindow.document.write(content);
      //
      // // Extract and append CSS styles to the new window
      // const stylesheets = Array.from(document.styleSheets);
      // stylesheets.forEach((stylesheet) => {
      //   if (stylesheet.href) {
      //     const linkTag = that.printWindow.document.createElement('link');
      //     linkTag.rel = 'stylesheet';
      //     linkTag.href = stylesheet.href;
      //     that.printWindow.document.head.appendChild(linkTag);
      //   } else if (stylesheet.rules) {
      //     const styleTag = that.printWindow.document.createElement('style');
      //     styleTag.type = 'text/css';
      //     styleTag.innerHTML = Array.from(stylesheet.rules).map((rule) => rule.cssText).join('\n');
      //     that.printWindow.document.head.appendChild(styleTag);
      //   }
      // });
      //
      // await that.printWindow.document.write('</body></html>');

      // that.printWindow.onafterprint = function () {
      //   that.printWindow.close();
      // };

      // setTimeout(() => {
      //   printWindow.print();
      // }, 100);
    },
    completedForPrint(){
      let imgs = document.images,
          len = imgs.length,
          counter = 0;

      [].forEach.call( imgs, function( img ) {
        if(img.complete)
          incrementCounter();
        else
          img.addEventListener( 'load', incrementCounter, false );
      } );
      function incrementCounter() {
        counter++;
        if ( counter === len ) {
          window.print();
          window.close();
        }
      }

    },
    printData() {
      // let tableId = 'invoice'
      // let divToPrint = document.getElementById(tableId);
      // window.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>" + divToPrint.outerHTML);
      // i18nService.getActiveLanguage();
      // setTimeout(() => {
      //   window.print();
      //   // window.close();
      // }, 100)

      let _id = "invoice";
      if (this.type == "pdf_share") {
        this.DownloadPDF(
            _id,
            this.data.invoice_code,
            this.mobileFullData(this.data.customer.mobile) ?? ""
        );
      } else if (this.type == "pdf") {
        this.exportPDF(_id);
      } else {
        this.printContent();
      }
    },
    exportPDF(_id) {
      let that = this;
      let element = document.getElementById(_id);
      let width = element.clientWidth;
      let height = element.clientHeight;

      // let pdf = jsPdfExport80MM("p", "px", [width, height]);
      let pdf = jsPdfExport("p", "px", [width, height]);

      pdf.html(document.getElementById(_id), {
        html2canvas: {
          scale: 1,
        },
        callback: function (pdf) {
          pdf.save(that.fileName);
        },
      });
    },

    DownloadPDF(_id, filename, mobile) {
      let element = document.getElementById(_id);
      let width = element.clientWidth - 205;
      let height = element.clientHeight;

      let pdf = jsPdfExport("p", "px", [height, width]);

      pdf.html(document.getElementById(_id), {
        html2canvas: {
          scale: 1,
        },
        callback: function (pdf) {
          // Convert the PDF to a Blob
          var pdfBlob = new Blob([pdf.output("blob", {fontName: "Amiri"})], {
            type: "application/pdf",
          });

          // Create FormData and append the PDF Blob
          var formData = new FormData();
          formData.append("file", pdfBlob, `${filename}.pdf`);
          formData.append("upload", "sales_invoices");

          ApiService.post("/base/uploads/upload-file", formData)
              .then((response) => {
                var file = {
                  path: response.data.path,
                  name: response.data.name,
                  pathDB: response.data.location,
                  upload_date_time: response.data.upload_date_time,
                };

                // var data =`token=yxndbq4x27838fu3&to=${mobile}&body=${encodeURIComponent(file.path)}`;

                // var xhr = new XMLHttpRequest();
                // xhr.withCredentials = false;

                // xhr.addEventListener("readystatechange", function () {
                //   if (this.readyState === this.DONE) {
                //     console.log(this.responseText);
                //   }
                // });

                // xhr.open(
                //   "POST",
                //   "https://api.ultramsg.com/instance19095/messages/chat"
                // );
                // xhr.setRequestHeader(
                //   "Content-Type",
                //   "application/x-www-form-urlencoded"
                // );
                // xhr.send(data);
                // alert('تم إرسال رابط الملف على الواتس اب بنجاح');
                // var base_url = window.location.origin;
                // window.location.href = `${base_url}/sales/sales_invoices`;
                window.open(
                    `https://api.whatsapp.com/send/?phone=${mobile}&text=${encodeURIComponent(file.path)}`,
                    "_blank"
                );
                var base_url = window.location.origin;
                window.location.href = `${base_url}/sales/sales_invoices`;
              })
              .catch(
                  function (err) {
                    console.log(err);
                  }.bind(this)
              );
        },
      });
    },

    async printSetting() {
      await ApiService.get(`${this.templateMainRoute}/${this.template_for}`).then((response) => {
        this.template = response.data.data;
        this.content_temp = response.data.data.content_temp;
        this.is_break_before_footer = response.data.data.is_break_before_footer ? response.data.data.is_break_before_footer : false;
        this.is_payment_details = response.data.data.is_payment_details ? response.data.data.is_payment_details : false;
        this.is_empty_row = response.data.data.is_empty_row ? response.data.data.is_empty_row : false;
        this.is_show_contact = response.data.data.is_show_contact ? response.data.data.is_show_contact : false;
        this.print_size = response.data.data.print_size ? response.data.data.print_size : '80';
        this.is_show_customer_details = response.data.data.is_show_customer_details ? response.data.data.is_show_customer_details : false;
        this.is_show_terms_and_conditions = response.data.data.is_show_terms_and_conditions ? response.data.data.is_show_terms_and_conditions : false;
        this.products_template_design = response.data.data.products_template_design ? response.data.data.products_template_design : 1;
        this.product_table_setting = response.data.data.product_table_setting ? response.data.data.product_table_setting : [];
        this.customer_branch = response.data.data.customer_branch ? response.data.data.customer_branch : false;
        this.label_shortcut_details = response.data.data.label_shortcut_details ? response.data.data.label_shortcut_details : [];
        this.is_use_label_shortcut = response.data.data.is_use_label_shortcut ? response.data.data.is_use_label_shortcut : false;
      });
    },
    inProductTableSetting(content) {
      if (this.product_table_setting.includes(content))
        return true;
      else
        return false;
    },
    inContentTemp(content) {
      if (this.content_temp.includes(content))
        return true;
      else
        return false;
    },
  },
  mounted() {
    let promise = this.getData();
    let promise1 = this.printSetting();
    Promise.all([promise, promise1]).then(() => {
      this.printData();
    });
  },
};
</script>

<style>
@media print {
  @page {
    size: auto;
    margin: 2mm;
  }
}

.store-logo {
  position: absolute;
  top: 0;
  right: 0;
  height: 99px;
  margin: 13px;
}

#invoice {
  font-family: "Amiri";
  width: 76mm;
  position: relative;
}

h5 {
  margin: 0;
}

html, body {
  margin: 0.39mm 0.27mm;
  background: #EEF0F8;
}

table, thead, tr, th, tbody, td {

  font-size: 14px;
  background-color: transparent !important;
}

table {
  border: 1px solid #000000;
  border-radius: 6px;
  border-color: #000000 !important;
  width: 100%;
}

.text-center {
  text-align: center !important;
}

th, p, h5 {

  font-size: 14px !important;

}

.special-border {
  padding: 5px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}

.table td {
  border-left: 1px solid #000000 !important;
  border-top: 0;
}

.table thead th {
  border-left: 1px solid #000000;
  border-bottom: 1px solid #000000;
}

.table thead tr {
  border: 0 !important;
}

.table thead th:last-child {
  border-left: 0 !important;
}

.table td:last-child {
  border-left: 0 !important;
}

.table th, .table td {
  padding: 5px !important;
}
.table-p td {
  border-left: 1px solid #000000 !important;
  border-top: 0 !important;
}

.table-p thead th {
  border-left: 1px solid #000000 !important;
  border-bottom: 1px solid #000000 !important;
}

.table-p thead tr {
  border: 0 !important;
}

.table-p thead th:last-child {
  border-left: 0 !important;
}

.table-p td:last-child {
  border-left: 0 !important;
}

.table-p th, .table-p td {
  padding: 5px !important;
}
</style>